<script>
import { fetchPrivacyNotice } from '@/api'
import { getPrivacyNoticeApiKey } from '@/helpers/privacy'

export default {
  metaInfo() {
    return {
      title: this.type,
      titleTemplate: '%s - Privacy notice - Europe PMC',
      meta: [{ name: 'description', content: this.type }],
    }
  },
  props: {
    type: {
      type: String,
      default: 'website',
    },
  },
  data() {
    return {
      privacyNotice: null,
    }
  },
  computed: {
    content() {
      const { privacyNotice } = this

      let content = privacyNotice ? privacyNotice.body : ''
      if (
        content.includes('h2') &&
        !content.includes('h3') &&
        content.includes('h4')
      ) {
        content = content.replace(/h4/g, 'h3')
      }

      const temp = document.createElement('div')
      temp.innerHTML = content
      const invalidElements = temp.querySelectorAll('ul .field-items')
      if (invalidElements.length) {
        for (let i = 0; i < invalidElements.length; i++) {
          const ul = invalidElements[i].closest('ul')
          ul.outerHTML = ul.innerHTML
        }
      }

      return temp.innerHTML
    },
  },
  async created() {
    const key = getPrivacyNoticeApiKey(this.type)
    const response = await fetchPrivacyNotice(key)
    this.privacyNotice = response ? response.nodes[0].node : null
  },
}
</script>
<template>
  <div id="privacy-notice">
    <div class="grid-row">
      <div class="col-11">
        <template v-if="privacyNotice">
          <h1>{{ privacyNotice.title }}</h1>
          <div>
            Last changed: {{ privacyNotice.changed }}
            <!--removed version count from UI since we use different endpoint to fetch privacy notice which has higher versions went under multiple reviews-->
          </div>
          <div v-if="content" v-html="content" />
        </template>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#privacy-notice {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
}
</style>
